import { FiCalendar, FiCompass, FiHome, FiSettings, FiStar, FiTrendingUp, FiTruck, FiUser } from 'react-icons/fi';


export const LinkItems = [
  {
    name: 'Fleet',
    icon: FiTruck,
    url: 'fleet',
    user_permissions: [],
    location_permissions: ['admin']
  },


]


export const LinkItemsOld = [
  {
    name: 'Home',
    icon: FiHome,
    url: '/dashboard',
    user_permissions: [],
    location_permissions: ['admin']
  },
  {
    name: 'Reports',
    icon: FiStar, subItems: [
      {
        name: 'Fleet',
        icon: FiCompass,
        url: '/dashboard/fleet',
        user_permissions: [],
        location_permissions: []
      },
      {
        name: 'Trucks',
        icon: FiCompass,
        url: '/dashboard/truck-summary',
        user_permissions: [],
        location_permissions: ['admin']
      },
      {
        name: 'Admin Fleet',
        icon: FiCompass,
        url: '/dashboard/admin/fleet',
        user_permissions: [],
        location_permissions: ['admin']
      },
      {
        name: 'Fleet',
        icon: FiCompass,
        url: '/dashboard/admin/fleet',
        user_permissions: [],
        location_permissions: ['admin']
      },
      {
        name: 'Schedule',
        icon: FiCalendar,
        url: 'schedule',
        user_permissions: [],
        location_permissions: ['admin']
      },
      {
        name: 'Accounts',
        icon: FiUser,
        url: 'accounts',
        user_permissions: [],
        location_permissions: ['admin']
      }
    ]},
  
]