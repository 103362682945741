import axios from 'axios';
import Cookies from 'js-cookie';

// Create axios instance
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Request interceptor
instance.interceptors.request.use((config) => {
  const token = Cookies.get('accessToken');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Response interceptor
instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && originalRequest.url.includes('refresh')) {
      Cookies.remove('accessToken');
      Cookies.remove('refreshToken');
      // Add logic to navigate to the sign-in page here
      //window.location.href = '/auth/sign-in'; // Adjust this URL to your login route
      return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = Cookies.get('refreshToken');
      return instance
        .post('/user/refresh', { refreshToken })
        .then((res) => {
          if (res.status === 200) {
            Cookies.set('accessToken', res.data.token); // Make sure to use the correct property for the token
            originalRequest.headers.Authorization = `Bearer ${res.data.token}`; // Update the original request with the new token
            return instance(originalRequest);
          }
        })
        .catch(() => {
          Cookies.remove('accessToken');
          Cookies.remove('refreshToken');
          // Add logic to navigate to the sign-in page here
          //window.location.href = '/auth/sign-in'; // Adjust this URL to your login route
        });
    }

    return Promise.reject(error);
  }
);

export default instance;
