import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
    Box,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    Textarea,
    Input,
    FormLabel,
    Button, Card, CardHeader, HStack, CardBody, Flex,
} from '@chakra-ui/react';
import grab from '../../../utils/grab';
import { useAtom } from 'jotai';
import { userAtom } from './../../../atoms';

function AccountDetails() {
    const [accountData, setAccountData] = useState({});
    const { accountNum } = useParams();
    const [user, setUser] = useAtom(userAtom);

    useEffect(() => {
        if (user.locationId) {
            console.log('firing')
            fetchData();
        }

    }, [user.locationId]); // Refetch data if accountNum changes

    const fetchData = async () => {
        try {
            //setSearhing(true);
            const response = await grab.get(`/accounts`, {
                params: {
                    locationId: user.locationId,
                    accountNum: accountNum // Pass accountNum as a parameter
                }
            });
            setAccountData(response.data[0]);
        } catch (error) {
            //setSearhing(false);
            console.error('Error fetching data:', error);
        } finally {
            //setSearhing(false);
        }
    };

    const handleDataChange = (field, value) => {
        setAccountData((prev) => ({
            ...prev,
            [field]: value
        }));
    };

    return (
        <Flex>
        {!accountData ?
        <Flex></Flex>
        :
            <Card width={'100%'} boxShadow="base" rounded="md">
                <CardHeader>
                    <HStack justify={'space-between'}>
                        <Text fontSize="xl" fontWeight="bold">
                            {accountData.Name}
                        </Text>

                    </HStack>
                </CardHeader>
                <CardBody>
                    <Tabs isLazy variant="enclosed">
                        <TabList>
                            <Tab>Info</Tab>
                            <Tab>Deliveries</Tab>
                            <Tab>Schedule</Tab>
                            <Tab>Notes</Tab>
                        </TabList>

                        <TabPanels>
                            {/* Info Tab */}
                            <TabPanel>
                                <FormLabel>Name</FormLabel>
                                <Input
                                    value={accountData.Name}
                                    onChange={(e) => handleDataChange('Name', e.target.value)}
                                />
                                <FormLabel mt={2}>Account Number</FormLabel>
                                <Input
                                    value={accountData.AccountNum}
                                    onChange={(e) => handleDataChange('AccountNum', e.target.value)}
                                />
                                {/* Continue with other fields similarly... */}
                            </TabPanel>

                            {/* Deliveries Tab */}
                            <TabPanel>
                                {/* Your Deliveries content goes here */}
                                <Text>Display and edit deliveries data here...</Text>
                            </TabPanel>

                            {/* Schedule Tab */}
                            <TabPanel>
                                {/* Your Schedule content goes here */}
                                <Text>Display and edit schedule data here...</Text>
                            </TabPanel>

                            {/* Notes Tab */}
                            <TabPanel>
                                <FormLabel>Notes</FormLabel>
                                <Textarea
                                    value={accountData.Notes || ""}
                                    onChange={(e) => handleDataChange('Notes', e.target.value)}
                                    placeholder="Enter notes here..."
                                />
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                    <Button mt={4} colorScheme="blue">
                        Save Changes
                    </Button>
                </CardBody>
            </Card>
            }
        </Flex>
    );
}

export default AccountDetails;
