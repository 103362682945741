
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import { v4 as uuidv4 } from 'uuid';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { userAtom } from './../../atoms';
import Cookies from 'js-cookie';



function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const [user, setUser] = useAtom(userAtom);
  const toast = useToast();
  const navigate = useNavigate();


  useEffect(() => {
    // check if a device ID exists
    const existingDeviceId = Cookies.get('deviceId');
    if (!existingDeviceId) {
      // create a new ID and store it
      const newDeviceId = uuidv4();
      Cookies.set('deviceId', newDeviceId);
      setDeviceId(newDeviceId);
    } else {
      setDeviceId(existingDeviceId);
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/user/login`, {
        usernameOrEmail: email,
        password,
        deviceId
      });

      // store tokens in local storage
      Cookies.set('accessToken', response.data.token, { expires: 7 }); // 7 days expiry
      Cookies.set('refreshToken', response.data.refreshToken, { expires: 100 }); // 7 days expiry
      setUser({
        username: response.data.username,
        email: response.data.email,
      });
      
      setIsLoading(false);
      navigate('/dashboard');
      
    } catch (error) {
      setIsLoading(false);
      toast({
        title: "Login Failed.",
        description: error.response.data.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}>
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <Stack align={'center'}></Stack>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}
          minW='500px'
          >
          <Stack spacing={4}>
            <Heading fontSize={'4xl'} mb={5}>Digital Dispatcher</Heading>
            <FormControl id="email">
              <FormLabel>Email address</FormLabel>
              <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
            </FormControl>
            <FormControl id="password">
              <FormLabel>Password</FormLabel>
              <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
            </FormControl>
            <Stack spacing={10}>
              <Stack
                direction={{ base: 'column', sm: 'row' }}
                align={'start'}
                justify={'space-between'}>
                <Text color={'blue.400'} fontSize='sm'>Forgot password?</Text>
              </Stack>
              <Button
                bg={'blue.400'}
                color={'white'}
                _hover={{
                  bg: 'blue.500',
                }}
                onClick={handleSubmit}
                isLoading={isLoading}
                loadingText="Signing in"
              >
                {isLoading ? <Spinner /> : 'Sign in'}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  )
}

export default Login;
