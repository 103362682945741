// Layout.js
import React from 'react';
import SideBar from '../Sidebar';

function DashboardLayout({ children }) {
  return (
      <SideBar>
        {children}
      </SideBar>
  );
}

export default DashboardLayout;
