import React, { useRef, useEffect, useState } from 'react';
import 'ol/ol.css';
import { Map, View, Overlay } from 'ol';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import OSM from 'ol/source/OSM';
import { Draw, Select } from 'ol/interaction';
import { click } from 'ol/events/condition';
import { Button, Box, Flex, CloseButton, useToast } from '@chakra-ui/react';
import { fromLonLat } from 'ol/proj';
import { Feature } from 'ol';
import { Point } from 'ol/geom';
import { Style, Icon } from 'ol/style';
import { FiMapPin } from 'react-icons/fi';


const MyMap = (props) => {
    const mapRef = useRef();
    const toast = useToast();
    const popupRef = useRef();
    const [drawMode, setDrawMode] = useState(false);
    const [draw, setDraw] = useState();
    const [popupContent, setPopupContent] = useState('');
    const [map, setMap] = useState();
    const [vectorSource, setVectorSource] = useState(new VectorSource());
    const markerStyle = new Style({
      image: new Icon({
        src: 'https://icons.iconarchive.com/icons/icons-land/vista-map-markers/256/Map-Marker-Marker-Outside-Azure-icon.png',
        scale: 0.15,
      }),
    });

    const closePopup = () => {
        if (map) {
          map.getOverlays().item(0).setPosition(undefined);
        }
        setPopupContent('');
      };
  
    // Create the map
    useEffect(() => {
      const center = fromLonLat([-83.988251, 33.955002]);
  
      const osmLayer = new TileLayer({
        source: new OSM(),
      });
  
      const vectorLayer = new VectorLayer({
        source: vectorSource,
        style: markerStyle,
        selectable: true, // Mark this layer as selectable
      });
  
      const newMap = new Map({
        target: mapRef.current,
        layers: [osmLayer, vectorLayer],
        view: new View({
          center: center,
          zoom: 12,
        }),
      });
  
      setMap(newMap);
  
      return () => newMap.setTarget(undefined); // Cleanup on unmount
    }, [vectorSource]);
  
    // Create the markers
    useEffect(() => {
      if (map) {
        const markers = [
          new Feature({
            geometry: new Point(fromLonLat([-83.988251, 33.955002])),
            name: 'Marker 1',
          }),
          new Feature({
            geometry: new Point(fromLonLat([-83.87251, 33.956002])),
            name: 'Marker 2',
          }),
          new Feature({
            geometry: new Point(fromLonLat([-84.010350, 33.995070])),
            name: 'Marker 3',
            title: 'My House',
          }),
        ];
  
        vectorSource.addFeatures(markers);
      }
    }, [map, vectorSource]);

    const processSelectedMarkers = (selectedMarkers) => {
        selectedMarkers.forEach((markerName) => {
          toast({
            title: "Selected Marker",
            description: markerName,
            status: "info",
            duration: 3000,
            isClosable: true,
          });
        });
      };

    useEffect(() => {
        if (map) {
          if (drawMode) {
            const drawInteraction = new Draw({
                source: vectorSource,
                type: 'Polygon',
                freehand: true, // Enable freehand drawing
              });
      
              drawInteraction.on('drawend', (event) => {
                  const drawnFeature = event.feature; // This is the drawn polygon feature
                  const polygonGeometry = drawnFeature.getGeometry();
                  const polygonExtent = polygonGeometry.getExtent();
                  const selectedMarkers = [];
                  vectorSource.forEachFeature((feature) => {
                    if (feature.get('name') && polygonGeometry.intersectsExtent(feature.getGeometry().getExtent())) {
                      console.log("Marker inside polygon:", feature.get('name'));
                      selectedMarkers.push(feature.get('name'));
                    }
                  });
                  processSelectedMarkers(selectedMarkers);
                });
                
                
              map.addInteraction(drawInteraction);
              setDraw(drawInteraction);
          } else if (draw) {
            map.removeInteraction(draw); // Remove the draw interaction
            setDraw(undefined); // Reset the draw interaction state
          }
        }
      }, [map, drawMode, vectorSource]);


    
// Handle popup overlay and select interaction
useEffect(() => {
    if (map) {
      const select = new Select({
        layers: (layer) => layer.get('selectable') === true,
        condition: click,
        style: markerStyle,
      });
  
      const popup = new Overlay({
        element: popupRef.current,
        positioning: 'bottom-center',
        stopEvent: false,
        offset: [0, -10],
      });
  
      map.addOverlay(popup);
      map.addInteraction(select);
  
      select.on('select', (event) => {
        const selectedFeature = event.selected[0];
        if (selectedFeature) {
          const name = selectedFeature.get('name');
          const coordinates = selectedFeature.getGeometry().getCoordinates();
          popup.setPosition(coordinates); // Set the position using the overlay
          setPopupContent(name);
        }
      });
  
      return () => { // Cleanup
        map.removeOverlay(popup);
        map.removeInteraction(select);
      };
    }
  }, [map]);


  useEffect(() => {
    if (map) {
      map.on('click', (event) => {
        // Check if the click event was on a feature
        const featureAtPixel = map.forEachFeatureAtPixel(event.pixel, (feature) => feature);

        // If no feature was clicked, close the popup
        if (!featureAtPixel) {
          closePopup();
        }
      });
    }

    return () => {
      if (map) {
        map.un('click', closePopup); // Cleanup event listener on unmount
      }
    };
  }, [map]);
  
  


  

  return (
    <Flex position="relative" width="100%" height="400px" direction="column">
      <Box ref={mapRef} width="100%" flex="1" />
      <Button
        position="absolute"
        bottom="3%"
        left="10px"
        onClick={() => setDrawMode(!drawMode)}
      >
        {drawMode ? 'Stop Drawing' : 'Draw'}
      </Button>
      <Box
        ref={popupRef}
        position="absolute"
        width={'200px'}
        height={'100px'}
        backgroundColor="white"
        padding="15px"
        borderRadius="10px"
        bottom="12px"
        left="50%"
        transform="translateX(-50%)"
      >
        <CloseButton position="absolute" right="5px" top="5px" onClick={closePopup} /> {/* Close button */}

        <Box>{popupContent}</Box>
      </Box>
    </Flex>
  );
};

export default MyMap;
