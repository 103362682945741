import React, { useState, useEffect, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import {
  Box,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Button,
  Text,
  HStack,
  VStack,
  Grid,
  Card,
  CardBody,
  CardHeader,
  Spinner
} from '@chakra-ui/react';
import DatePicker from "react-datepicker";
import { CalendarIcon } from '@chakra-ui/icons';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import grab from '../../utils/grab';

function TruckSummary() {
  const [rowData, setRowData] = useState([]);
  const [showFilters, setShowFilters] = useState(true);
  const [searching, setSearhing] = useState(false);
  const gridApi = useRef(null);

  const current = new Date();
  current.setHours(0,0,0,0);
  const nextDay = new Date(current);
  nextDay.setDate(nextDay.getDate() + 1);

  const [startDate, setStartDate] = useState(current);
  const [endDate, setEndDate] = useState(nextDay);

  useEffect(() => {
    gridApi.current && gridApi.current.sizeColumnsToFit();
  }, [rowData, showFilters]);

  useEffect(() => {
    fetchData();
    window.addEventListener('resize', () => {
      gridApi.current && gridApi.current.sizeColumnsToFit();
    });
  }, []);

  const fetchData = async () => {
    try {
      setSearhing(true)
      const response = await grab.get('/truck-report');
      setSearhing(false)
      if (response.status === 200) {
        setRowData(response.data);
        console.log(response.data);
      } else {
        console.warn('Unexpected response status:', response.status);
      }
    } catch (error) {
      setSearhing(false)
      console.error('Error fetching data:', error);
    }
  };

  const handleSearch = () => {
    fetchData();
  };

  const columnDefs = [
    { headerName: "Truck Number", field: "TruckNum" },
    { headerName: "Sum Loaded", field: "sumLoaded" },
    { headerName: "Gallons On Board", field: "galOnBoard" },
    { headerName: "Sum Dispatched", field: "sumDispatched" },
    { headerName: "WC Delivered", field: "WC_Delivered" },
    { headerName: "Auto Delivered", field: "AutoDelivered" },
    { headerName: "Sum Delivered", field: "sumDelivered" },
    { headerName: "WC Dispatched", field: "WC_Dispatched" },
    { headerName: "Auto Dispatched", field: "Auto_Dispatched" },
    { headerName: "Count Dispatched", field: "CountDispatched" },
    { headerName: "Count Complete", field: "CountComplete" },
    { headerName: "Count Incomplete", field: "CountIncomplete" },
    { headerName: "Sum Checks", field: "sum_Chks" },
    { headerName: "Sum Cash", field: "sum_Cash" },
    { headerName: "ROA", field: "ROA" },
    { headerName: "WC ROA Gallons", field: "WC_ROA_Gallons" },
    { headerName: "WC ROA Dispatched", field: "WC_ROA_Dispatched" },
    { headerName: "Auto Gallons", field: "Auto_Gallons" }
  ];
  

  const defaultColDef = {
    cellStyle: { 'textAlign': 'left' },
    minWidth: 150
  };

  const onFirstDataRendered = params => {
    params.api.sizeColumnsToFit();
  };

  const DateInput = React.forwardRef(({ value, onClick }, ref) => (
    <InputGroup>
    <InputLeftElement
      pointerEvents="none"
      children={<CalendarIcon color="gray.400" />}
    />
    <Input
      onClick={onClick}
      value={value}
      ref={ref}
      placeholder="Select date"
    />
  </InputGroup>
  ));

  return (
    <Box width={'100%'} height={'100%'}>
        <Grid templateColumns={showFilters ? { base: '1fr', md: '8fr 2fr' } : { base: '1fr' }} gap={3} minH="100vh">

        <Card width={"100%"} height={'100%'} minH='500px'>
          <CardHeader>
            <HStack justify={'space-between'}>
              <Text fontSize="xl" fontWeight="bold">
                Truck Summary Report
              </Text>
              <Button onClick={()=> showFilters ? setShowFilters(false) : setShowFilters(true)} colorScheme="blue" mt={3}>
                  {showFilters ? 'Hide Filter' : 'Show Filter'}
              </Button>
            </HStack>
          </CardHeader>
          <CardBody>
            <Box className="ag-theme-alpine" width={"100%"} height={'100%'}>
              <AgGridReact
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                rowData={rowData}
                animateRows={true}
                onGridReady={params => {
                  gridApi.current = params.api;
                  params.api.sizeColumnsToFit();
                }}
                onFilterChanged={params => params.api.sizeColumnsToFit()}
                enableFilter={true}
                onFirstDataRendered={onFirstDataRendered}
              />
            </Box>
          </CardBody>
        </Card>

        {showFilters && (
        <VStack spacing={3} align="start"> {/* Wrap the date selection card in a VStack */}
        
        <Card height="auto">
          <CardHeader>
            <Text fontWeight="bold">
              Select Date Range
            </Text>
          </CardHeader>
          <CardBody>
              <FormLabel>Start Date</FormLabel>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                w="30%"
                customInput={<DateInput />}
                showTimeSelect
              />
              <FormLabel mt={2} >End Date</FormLabel>
              <DatePicker
                selected={endDate}
                customInput={<DateInput />}
                onChange={(date) => setEndDate(date)}
                showTimeSelect
              />
              <Button onClick={handleSearch} colorScheme="blue" w={'40%'} mt={3}>
                { searching ? <Spinner size={'sm'}/> : 'Search'}
              </Button>
          </CardBody>
        </Card>
        
        </VStack>
        )}
      </Grid>
    </Box>
  );
}

export default TruckSummary;
