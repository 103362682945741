import React, { useEffect } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import FleetReport from './Pages/FleetReport';
import Home from './Pages/Home';
import Login from './Pages/Login';
import { theme } from '@chakra-ui/react';
import ProtectedWrapper from './components/ProtectedWrapper';
import DashboardLayout from './components/Layout/dashboardLayout';
import TruckSummary from './Pages/TruckSummary';
import TruckSummary2 from './Pages/TruckSummary2';
import FleetReportAdmin from './Pages/FleetReportAdmin';
import { useAtom } from 'jotai';
import { userAtom } from './atoms';
import Fleet from './Pages/Fleet';
import grab from './utils/grab';
import { useParams } from 'react-router-dom';
import { Spinner, Box, Center } from "@chakra-ui/react";
import Schedule from './Pages/Schedule';
import Accounts from './Pages/Accounts';
import AccountDetails from './Pages/Accounts/selectedAccount';
import EmbedLayout from './components/Layout/embedLayout';

function EmbedRoutes() {
  const [user, setUser] = useAtom(userAtom);
  const isAdmin = user?.user_permission === 'dd_admin';
  const { company } = useParams();

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        console.log(company)
        const response = await grab.get(`/company/locations/${company}`);
        //console.log(response)
        if (response.data && response.data.locationId && response.data.companyId) {

          // Update user object
          const updatedUser = {
            ...user,
            locationId: response.data.locationId,
            companyId: response.data.companyId
          };
          //console.log(updatedUser)
          setUser(updatedUser);
        }
      } catch (error) {
        console.error('Error fetching company details:', error);
      }
    };

    if (company) {
      fetchCompanyData();
    }
  }, [company]);



  return (
    <EmbedLayout>
      <Routes>
        <Route path="fleet" element={<Fleet />} />
      </Routes>
    </EmbedLayout>
  );
}



function DashboardRoutes() {
  const [user, setUser] = useAtom(userAtom);
  const isAdmin = user?.user_permission === 'dd_admin';
  const { company } = useParams();

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        console.log(company)
        const response = await grab.get(`/company/locations/${company}`);
        //console.log(response)
        if (response.data && response.data.locationId && response.data.companyId) {

          // Update user object
          const updatedUser = {
            ...user,
            locationId: response.data.locationId,
            companyId: response.data.companyId
          };
          //console.log(updatedUser)
          setUser(updatedUser);
        }
      } catch (error) {
        console.error('Error fetching company details:', error);
      }
    };

    if (company) {
      fetchCompanyData();
    }
  }, [company]);



  return (
    <DashboardLayout>
      <Routes>
        <Route path="/" element={<Home />} />
        {isAdmin && (
          <Route path="admin/fleet" element={<FleetReportAdmin />} />
        )}
          <Route path="fleet" element={<Fleet />} />
          <Route path="schedule" element={<Schedule />} />
          <Route path="truck-summary" element={<TruckSummary />} />
          <Route path="truck-summary2" element={<TruckSummary2 />} />
          <Route path="accounts" element={<Accounts />} />
          <Route path="accounts/:accountNum" element={<AccountDetails />} />
      </Routes>
    </DashboardLayout>
  );
}



function App() {
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/auth/sign-in" element={<Login />} />
          <Route path="/:company/embed/*" element={<EmbedRoutes />} />
          <Route path="/:company/dashboard/*" element={<DashboardRoutes />} />
          <Route
            path="*"
            element={<Navigate to="/kellydev/dashboard/fleet" replace />}
          />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}



export default App;
