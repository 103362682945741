import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormLabel,
  Badge,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  HStack,
  VStack,
  Grid,
  Card,
  CardBody,
  CardHeader,
  Spinner,
  Flex,
  Icon
} from '@chakra-ui/react';
import DatePicker from "react-datepicker";
import { CalendarIcon } from '@chakra-ui/icons';
import grab from '../../utils/grab';
import { FiUpload, FiTruck, FiCheckCircle } from 'react-icons/fi'; // Make sure to import FiTruck icon

function TruckSummary() {
  const [rowData, setRowData] = useState([]);
  const [selectedTruck, setSelectedTruck] = useState(null); // Selected truck details
  const [showFilters, setShowFilters] = useState(true);
  const [searching, setSearhing] = useState(false);

  const current = new Date();
  current.setHours(0,0,0,0);
  const nextDay = new Date(current);
  nextDay.setDate(nextDay.getDate() + 1);

  const [startDate, setStartDate] = useState(current);
  const [endDate, setEndDate] = useState(nextDay);

  const fetchData = async () => {
    try {
      setSearhing(true)
      const response = await grab.get('/truck-report');
      setSearhing(false)
      if (response.status === 200) {
        setRowData(response.data);
      } else {
        console.warn('Unexpected response status:', response.status);
      }
    } catch (error) {
      setSearhing(false)
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearch = () => {
    fetchData();
  };

  const DateInput = React.forwardRef(({ value, onClick }, ref) => (
    <InputGroup>
      <InputLeftElement
        pointerEvents="none"
        children={<CalendarIcon color="gray.400" />}
      />
      <Input
        onClick={onClick}
        value={value}
        ref={ref}
        placeholder="Select date"
      />
    </InputGroup>
  ));

  return (
    <Box width={'100%'} height={'100%'}>
        <Grid templateColumns={showFilters ? { base: '1fr', md: '8fr 2fr' } : { base: '1fr' }} gap={3} minH="100vh">
          <Card width={"100%"} height={'100%'} minH='500px'>
            <CardHeader>
              <HStack justify={'space-between'}>
                <Text fontSize="xl" fontWeight="bold">
                  Truck Summary
                </Text>
                <Button onClick={()=> showFilters ? setShowFilters(false) : setShowFilters(true)} colorScheme="blue" mt={3}>
                    {showFilters ? 'Hide Filter' : 'Show Filter'}
                </Button>
              </HStack>
            </CardHeader>
            <CardBody>
              {selectedTruck ? (
                <Box>
                  <Button  mb={4} colorScheme="blue" onClick={() => setSelectedTruck(null)}>Go Back</Button>
                  {Object.keys(selectedTruck).map(key => (
                    <HStack key={key} mb={2}>
                      <Text fontWeight="bold">{key}:</Text>
                      <Text>{selectedTruck[key]}</Text>
                    </HStack>
                  ))}
                  
                </Box>
              ) : (
                <Flex wrap="wrap">
                  {/* Displaying truck numbers */}
                  {rowData.map(truck => (
                    <>
                    <VStack 
                      p={4} 
                      ml={4} 
                      mb={4} 
                      onClick={() => setSelectedTruck(truck)} 
                      cursor="pointer" 
                      borderWidth="1px" 
                      borderRadius="lg" 
                      key={truck.TruckNum} 
                      minW='30%'
                      justify={'flex-start'}
                      align='flex-start'
                      _hover={{ backgroundColor: 'gray.100' }}
                      >
                    <HStack ml={2}>
                      <Icon as={FiTruck} fontSize='2xl'/>
                      <Text fontWeight='bold'>Truck {truck.TruckNum}</Text>
                    </HStack>
                    <HStack w={'100%'} justify='space-between'>
                        <Text colorScheme="blue" ml="1">{`Loaded: ${truck.sumLoaded}`}</Text>
                        <Text colorScheme="yellow" ml="1">{`Dispatched: ${truck.sumDispatched}`}</Text>
                        <Text colorScheme="green" ml="1">{`Delivered: ${truck.sumDelivered}`}</Text>
                    </HStack>
                    </VStack>
                  </>
                  ))}
                </Flex>
              )}
            </CardBody>
          </Card>
          {showFilters && (
            <VStack spacing={3} align="start">
              <Card height="auto">
                <CardHeader>
                  <Text fontWeight="bold">
                    Select Date Range
                  </Text>
                </CardHeader>
                <CardBody>
                    <FormLabel>Start Date</FormLabel>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      w="30%"
                      customInput={<DateInput />}
                      showTimeSelect
                    />
                    <FormLabel mt={2}>End Date</FormLabel>
                    <DatePicker
                      selected={endDate}
                      customInput={<DateInput />}
                      onChange={(date) => setEndDate(date)}
                      showTimeSelect
                    />
                    <Button onClick={handleSearch} colorScheme="blue" w={'40%'} mt={3}>
                      {searching ? <Spinner size={'sm'}/> : 'Search'}
                    </Button>
                </CardBody>
              </Card>
            </VStack>
          )}
        </Grid>
    </Box>
  );
}

export default TruckSummary;
