import React, { useEffect } from 'react';
import {
  Box,
  Text,
  Grid,
  Card,
  CardBody,
  CardHeader,
  Button,
  Select,
  FormControl, 
  FormLabel,
} from '@chakra-ui/react';
import Nav from '../../components/Navbar';
import MyMap from '../../components/MyMap';
import { useAtom } from 'jotai';
import { userAtom } from './../../atoms';

function Home() {
  const [user, setUser] = useAtom(userAtom);
  useEffect(() => {
    console.log(user)
  }, []);
  return (
    <Box textAlign="center" fontSize="xl">
      <Grid
        minH="100vh"
        p={1}
        templateColumns={{ base: "1fr", md: "7fr 3fr" }}
        gap={5}
      >
        <Card maxH={'20vh'}>
          <CardHeader>
            
            <Text fontSize="2xl" fontWeight="bold" >
              Hello, {user.companyId}
            </Text>
          </CardHeader>
          <CardBody>

          </CardBody>
        </Card>

      </Grid>
    </Box>
  );
}

export default Home;
