import React, { useState, useEffect, useRef, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Button,
  Text,
  HStack,
  VStack,
  Grid,
  Card,
  CardBody,
  CardHeader,
  Spinner,
  useToast,
  RadioGroup,
  Radio,
  Select,
  Spacer,
  IconButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UnorderedList,
  ListItem,
  ListIcon,
  Table,
  Thead,
  Th,
  Tr,
  Td,
  Tbody, Badge,

} from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { userAtom } from './../../atoms';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css'; // or 'ag-theme-material.css';
import 'ag-grid-enterprise';
import grab from '../../utils/grab'
import { CalendarIcon, HamburgerIcon } from '@chakra-ui/icons';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import Papa from 'papaparse';
import moment from 'moment';
import { FiAlertCircle, FiArrowRight, FiFilter } from 'react-icons/all';
import { FiCheckCircle } from 'react-icons/fi';

pdfMake.vfs = pdfFonts.pdfMake.vfs;


function Scheduling() {
  const [showFilters, setShowFilters] = useState(false);
  const [selectedAccountData, setSelectedAccountData] = useState([]);
  const [showAccountGrid, setShowAccountGrid] = useState(true);
  const [searching, setSearhing] = useState(false);
  const [user, setUser] = useAtom(userAtom);
  const current = moment.utc().startOf('day').toDate();
  const nextDay = moment.utc(current).add(1, 'days').toDate();
  const toast = useToast();
  const [startDate, setStartDate] = useState(current);
  const [endDate, setEndDate] = useState(nextDay);
  const gridApi = useRef(null);
  const gridRef = useRef(null);
  const saveTimeoutRef = useRef(null);
  const [divisions, setDivisions] = useState([]);
  const [selectedDivision, setSelectedDivision] = useState('All');
  const [deliveryGroups, setDeliveryGroups] = useState([]);
  const [selectedDG, setSelectedDG ]= useState('All');
  const [bolProducts, setBolProducts] = useState([]);
  const [selectedBol, setSelectedBol ]= useState('All');
  const [accountingProducts, setAccountingProducts] = useState([]);
  const [selectedAccountingProduct, setSelectedAccountingProduct ]= useState('All');
  const [selectedTickets, setSelectedTickets] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [generating, setGenerating] = useState("pending");


  useEffect(() => {
    let filterState = localStorage.getItem('filterState');
    setShowFilters(filterState === 'true');
    //window.addEventListener('resize', () => {
      //gridApi.current && gridApi.current.sizeColumnsToFit();
    //});
  }, []);

  useEffect(() => {
    //gridApi.current && gridApi.current.sizeColumnsToFit();
  }, [selectedAccountData, showAccountGrid, showFilters]);

  useEffect(() => {
    if (user.locationId){
      fetchAccountDetails();
    }
  }, [user.locationId]);

  const handleDivisionChange = (event) => {
    console.log(event.target.value)
    setSelectedDivision(event.target.value);
  };

  useEffect(() => {
    const fetchDivisions = async () => {
      try {
        const response = await grab.get('/divisions', {
          params: {
            locationId: user.locationId,
          },
        });
        setDivisions(response.data);
      } catch (error) {
        console.error('Error fetching divisions:', error);
      } finally {
      }
    };

    if (user?.locationId){
      fetchDivisions();
    }
  }, [user.locationId]);

  const handleDGChange = (event) => {
    setSelectedDG(event.target.value);
  };

  useEffect(() => {
    const fetchDeliveryGroups = async () => {
      try {
        const response = await grab.get('/delivery-group', {
          params: {
            locationId: user.locationId,
          },
        });
        setDeliveryGroups(response.data);
      } catch (error) {
        console.error('Error fetching divisions:', error);
      } finally {
      }
    };
    if (user?.locationId){
      fetchDeliveryGroups();
    }

  }, [user.locationId]);

  const handleBolChange = (event) => {
    console.log(event.target.value)
    setSelectedBol(event.target.value);
  };

  useEffect(() => {
    const fetchBols = async () => {
      try {
        const response = await grab.get('/bolcodes', {
          params: {
            locationId: user.locationId,
          },
        });
        setBolProducts(response.data);
      } catch (error) {
        console.error('Error fetching divisions:', error);
      } finally {
      }
    };

    if (user?.locationId){
      fetchBols();
    }
  }, [user.locationId]);


  const handleAccountingProductChange = (event) => {
    console.log(event.target.value)
    setSelectedAccountingProduct(event.target.value);
  };

  useEffect(() => {
    const fetchAccountingProducts = async () => {
      try {
        const response = await grab.get('/accounting-products', {
          params: {
            locationId: user.locationId,
          },
        });
        setAccountingProducts(response.data);
      } catch (error) {
        console.error('Error fetching divisions:', error);
      } finally {
      }
    };

    if (user?.locationId){
      fetchAccountingProducts();
    }
  }, [user.locationId]);


  const fetchAccountDetails = async (accountNum) => {
    try {
      setSearhing(true)
      const start = startDate.toISOString();
      const end = endDate.toISOString();
      //console.log(end)
      //console.log(endDate)
      const response = await grab.get(`/schedules`, {
        params: {
          locationId: user.locationId,
          startDate: start,
          endDate: end,
          division: selectedDivision,
          deliveryGroup: selectedDG,
          BOL: selectedBol,
          accountingProduct: selectedAccountingProduct
        }
      });
      console.log(`Fetched ${response.data.length} potential tickets.`);
      setSelectedAccountData(response.data);
      setSearhing(false)
    } catch (error) {
      console.error('Error fetching account details:', error);
      setSearhing(false)
    }
  };

  const handleSearch = () => {
    fetchAccountDetails();
  };

  const handleStartDateChange = (date) => {
    const daysDifference = moment(endDate).diff(moment(date), 'days');

    //if (daysDifference > 7) {
    //  toast({
    //    title: "Date Range Error",
    //    description: "You can only select a date range of up to 7 days.",
    //    status: "error",
    //    duration: 5000,
    //    isClosable: true,
    //  });
    //  return; // return early to stop the function here.
    //}

    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    //console.log(date)

    setEndDate(date);
  };

  const generateTickets = async () => {
    let updatedTickets = [...selectedTickets]; // Copy current selectedTickets to a new array

    for (let i = 0; i < selectedTickets.length; i++) {
      const ticket = selectedTickets[i]; // Define ticket outside the try block
      setGenerating(ticket.AccountNum);

      try {
        const response = await grab.get('/create-ticket', {
          params: {
            locationId: user.locationId,
            location: ticket.Location,
            accountNum: ticket.AccountNum,
            scheduleType: ticket.ScheduleType,
          },
        });

        // If successful, update the ticket in the copied array
        updatedTickets[i] = {
          ...ticket,
          TicketNum: response.data.TicketNum
        };

        // Update the state immediately after processing each ticket
        setSelectedTickets(prevTickets => {
          const updated = [...prevTickets];
          updated[i] = updatedTickets[i];
          return updated;
        });

      } catch (error) {
        console.error('Error creating ticket for account:', ticket.AccountNum, error);

        // If error, update the ticket in the copied array with an error message
        updatedTickets[i] = {
          ...ticket,
          error: error.message
        };

        // Update the state immediately after processing each ticket
        setSelectedTickets(prevTickets => {
          const updated = [...prevTickets];
          updated[i] = updatedTickets[i];
          return updated;
        });
      } finally {
        setGenerating('complete')
      }
    }

    // Clear all row selections in AG Grid
    // gridApi.current.deselectAll();

  };

  const exitModal = async () => {

    // Clear selected tickets
    setGenerating('pending');
    setSelectedTickets([])
    // Clear all row selections in AG Grid
    gridApi.current.deselectAll();

    // close modal
    onClose()
  };







  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Name",
      field: "Name",
      enableRowGroup: true,
      headerCheckboxSelection: true,
      checkboxSelection: true,
    },
    {
      headerName: "Last Delivery Date",
      field: "LastDeliveryDate",
      enableRowGroup: true,
      cellRenderer: (params) => {
        return new Date(params.value).toLocaleDateString();
      }
    },
    {
      headerName: "Next Delivery Date",
      field: "NextDeliveryDate",
      enableRowGroup: true,
      cellRenderer: (params) => {
        return new Date(params.value).toLocaleDateString();
      }
    },
    {
      headerName: "Last Delivery Quantity",
      field: "LastDeliveryQty",
      enableRowGroup: true
    },
    {
      headerName: "Last Generated Ticket Date",
      field: "LastGeneratedTicketDate",
      enableRowGroup: true,
      cellRenderer: (params) => {
        return new Date(params.value).toLocaleDateString();
      }
    },
    { headerName: "Account Number", field: "AccountNum", enableRowGroup: true },
    { headerName: "Location", field: "Location", enableRowGroup: true },
    { headerName: "Division Name", field: "DivisionName", enableRowGroup: true },
    { headerName: "Week", field: "week", enableRowGroup: true },
  ]);



  const defaultColDef = useMemo(() => {
    return {
      cellStyle: { 'textAlign': 'left' },
      menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
      filter: true,
      sortable: true,
      resizable: true,
      enableRowGroup: true,
      width: 200
    }
  }, []);

  const handleHideFilter = () => {
    const status = !showFilters;
    console.log(status);
    setShowFilters(status);
    localStorage.setItem('filterState', JSON.stringify(status));
  };

  const saveGridState = () => {
    if (saveTimeoutRef.current) {
      clearTimeout(saveTimeoutRef.current); // Clear existing timeout if present
    }

    // Set a new timeout to save the grid state after 2 seconds
    saveTimeoutRef.current = setTimeout(() => {
      if (gridRef.current) {
        const columnState = gridRef.current.columnApi.getColumnState();
        const columnGroupState = gridRef.current.columnApi.getColumnGroupState();
        const filterState = gridApi.current.getFilterModel();
        const gridState = { columnState, columnGroupState, filterState };
        localStorage.setItem('companyFleetGridState', JSON.stringify(gridState));
        console.log('grid saved');
      } else {
        console.warn('getColumnState is not a function');
      }
    }, 1000);
  };

  const handleSelectionChange = () => {
    const selectedRows = gridApi.current.getSelectedRows();
    console.log(selectedRows)
    setSelectedTickets(selectedRows);
  };



  // Load the grid's saved state
  const loadGridState = () => {
    const savedState = localStorage.getItem('companyFleetGridState');
    if (savedState) {
      const gridState = JSON.parse(savedState);
      console.log('loading state')
      gridRef.current.columnApi.applyColumnState({ state: gridState.columnState, applyOrder: true});
      gridRef.current.columnApi.setColumnGroupState(gridState.columnGroupState);
      gridApi.current.setFilterModel(gridState.filterState);
      console.log('loading state')
    }
  };

  const onGridReady = params => {
    gridApi.current = params.api;
    loadGridState(); // Load the saved grid state
  };

  const DateInput = React.forwardRef(({ value, onClick, onChange }, ref) => (
    <InputGroup>
    <InputLeftElement
      pointerEvents="none"
      children={<CalendarIcon color="gray.400" />}
    />
    <Input
      onClick={onClick}
      value={value}
      ref={ref}
      onChange={onChange}
      placeholder="Select date"
    />
  </InputGroup>
  ));
  
  
  return (
    <Box width={'100%'} height={'100%'}>
      <Grid templateColumns={showFilters ? { base: '1fr', md: '8fr 2fr' } : { base: '1fr' }} gap={3} minH="100vh">
        <Card width={"100%"} height={'100%'} minH='500px'>
          <CardHeader>
            <HStack justify={'space-between'}>
              <Text fontSize="xl" fontWeight="bold">
                Delivery Schedule
              </Text>
              <HStack>
                <Button
                  onClick={onOpen}  // This opens the modal
                  colorScheme="blue"
                  isDisabled={!selectedTickets.length}
                >
                  Generate Tickets
                </Button>
                <IconButton
                  onClick={handleHideFilter}
                  colorScheme="blue"
                  aria-label={showFilters ? 'Hide Filter' : 'Show Filter'}
                  icon={<FiFilter />}

                />
              </HStack>


            </HStack>
            
          </CardHeader>
          <CardBody>
          <Box className="ag-theme-alpine" width={"100%"} height={580}>
              <AgGridReact
                ref={gridRef}
                showFooter={true}

                columnDefs={columnDefs}
                rowData={selectedAccountData}
                defaultColDef={defaultColDef}
                onToolPanelVisibleChanged={saveGridState}
                onColumnVisible={saveGridState} // When a column is shown/hidden
                onColumnPinned={saveGridState}   // When a column is pinned/unpinned
                onColumnMoved={saveGridState}
                rowSelection={'multiple'}
                suppressRowClickSelection={true}
                onColumnResized={saveGridState}
                onColumnRowGroupChanged={saveGridState}
                onSelectionChanged={handleSelectionChange}
                sideBar={{
                  toolPanels: [
                    {
                      id: 'columns',
                      labelDefault: 'Columns',
                      labelKey: 'columns',
                      iconKey: 'columns',
                      toolPanel: 'agColumnsToolPanel',
                    },
                    {
                      id: 'filters',
                      labelDefault: 'Filters',
                      labelKey: 'filters',
                      iconKey: 'filter',
                      toolPanel: 'agFiltersToolPanel',
                    },
                    // other tool panels as needed
                  ],
                  defaultToolPanel: null, // This will have the sidebar closed by default
                }}

                onGridReady={onGridReady}
                rowGroupPanelShow='always'



              />
          </Box>
          </CardBody>
        </Card>
        {showFilters && (
        <VStack spacing={3} align="start"> {/* Wrap the date selection card in a VStack */}

          <Card>
            {showFilters && (
              <VStack spacing={3} align="start">
                <Card height="auto">
                  <CardHeader>
                    <Text fontWeight="bold">Search Schedule</Text>
                  </CardHeader>
                  <CardBody>

                    {/* Date Picker */}
                    <FormLabel mt={-5}>Date</FormLabel>
                    <DatePicker
                      selected={endDate}
                      onChange={handleEndDateChange}
                      w="30%"
                      customInput={<DateInput />}
                    />
                    <Spacer h={3}/>
                    {/* 4 dropdown selects */}
                    <VStack spacing={2} align="start" >
                      <FormControl>
                        <FormLabel>Division</FormLabel>
                        <Select
                          defaultValue="All"
                          value={selectedDivision}
                          onChange={handleDivisionChange}
                        >
                          <option value="All">All</option>
                          {divisions.map((division) => (
                            <option key={division.divisionNumber} value={division.divisionNumber}>
                              {division.divisionName}
                            </option>
                          ))}
                        </Select>
                      </FormControl>

                      <FormControl>
                        <FormLabel>Delivery Group</FormLabel>
                        <Select
                          defaultValue="All"
                          value={selectedDG}
                          onChange={handleDGChange}
                        >
                          <option value="All">All</option>
                          {deliveryGroups.map((dg) => (
                            <option key={dg.DeliveryGroup} value={dg.DeliveryGroup}>
                              {dg.DeliveryGroup}
                            </option>
                          ))}
                        </Select>
                      </FormControl>

                      <FormControl>
                        <FormLabel>BOL Product</FormLabel>
                        <Select
                          defaultValue="All"
                          value={selectedBol}
                          onChange={handleBolChange}
                        >
                          <option value="All">All</option>
                          {bolProducts.map((bol) => (
                            <option key={bol.BOLCode} value={bol.BOLCode}>
                              {bol.BOLDescription}
                            </option>
                          ))}
                        </Select>
                      </FormControl>

                      <FormControl>
                        <FormLabel>Accounting Product</FormLabel>
                        <Select
                          defaultValue="All"
                          value={selectedAccountingProduct}
                          onChange={handleAccountingProductChange}
                        >
                          <option value="All">All</option>
                          {accountingProducts.map((product) => (
                            <option key={product.fuelType} value={product.fuelType}>
                              {product.description}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                    </VStack>
                    <Spacer h={3}/>

                    {/* 4 radio buttons */}
                    <VStack width={'100%'} spacing={2} align="start" mt={3}>
                      <RadioGroup defaultValue="All" width={'100%'} >
                        <VStack width={'100%'} align={'flex-start'}>
                          <Radio value="All">All</Radio>
                          <Radio value="Scheduled Deliver">Scheduled Deliver</Radio>
                          <Radio value="Hot Water/Daily Use">Hot Water/Daily Use</Radio>
                          <Radio value="Degree-Day">Degree-Day</Radio>
                        </VStack>
                      </RadioGroup>
                    </VStack>
                    <Spacer h={3}/>


                    <Button onClick={handleSearch} colorScheme="blue" mt={3}>
                      {searching ? <Spinner size={'sm'} /> : 'Show Schedule'}
                    </Button>

                  </CardBody>
                </Card>
              </VStack>
            )}
          </Card>


        </VStack>
        )}
      </Grid>
      <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Ticket Generation</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            { generating === 'pending' ?
              <Text>Are you sure you want to create tickets for these accounts?</Text>
            :
              null
            }
              <Table variant="simple" mt={4}>
                <Thead>
                  <Tr>
                    <Th>Account Name</Th>
                    <Th>Status</Th>
                    <Th>Ticket Number</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {selectedTickets.map(ticket => (
                    <Tr key={ticket.AccountNum}>
                      <Td>
                        <Text fontWeight={'bold'}>{ticket.Name}</Text>
                      </Td>
                      <Td>
                        {generating === ticket.AccountNum ? (
                          <Badge fontSize='0.8em' colorScheme="orange">
                            <Spinner size="xs" mr={2} />Generating
                          </Badge>
                        ) : (
                          <Badge fontSize='0.8em' colorScheme={ticket.TicketNum ? "green" : ticket.error ? "red" : "blue"}>
                            {ticket.TicketNum ? "Success" : ticket.error ? "Error" : "Pending"}
                          </Badge>
                        )}
                      </Td>
                      <Td>
                        {ticket.TicketNum || '-'}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>

          </ModalBody>
          <ModalFooter>
            { generating === 'complete' ?
                <Button
                  onClick={exitModal}
                  mr={3} colorScheme="blue"
                >
                  Exit
                </Button>
              :
              <>
                <Button onClick={onClose} mr={3}>Cancel</Button>
                <Button
                  colorScheme="blue"
                  onClick={generateTickets}
                  isDisabled={generating !== 'pending'}

                >
                  {generating !== 'pending' ? <Spinner size="xs" mr={2} /> : null }
                  {generating === 'pending' ? "Generate" : "Generating" }

                </Button>
              </>
            }

          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default Scheduling;
