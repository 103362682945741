import React, { useState, useEffect, useRef, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Button,
  Text,
  HStack,
  VStack,
  Grid,
  Card,
  CardBody,
  CardHeader,
  Spinner
} from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { userAtom } from './../../atoms';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css'; // or 'ag-theme-material.css';
import 'ag-grid-enterprise';
import grab from '../../utils/grab'
import { CalendarIcon } from '@chakra-ui/icons';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import Papa from 'papaparse';
pdfMake.vfs = pdfFonts.pdfMake.vfs;


function AccountsGrid({rowData, handleRowClick}) {
  const saveTimeoutRef = useRef(null);
  const gridApi = useRef(null);
  const gridRef = useRef(null);

  useEffect(() => {
    window.addEventListener('resize', () => {
      gridApi.current && gridApi.current.sizeColumnsToFit();
    });
  }, []);


  const defaultColDef = useMemo(() => {
    return {
      cellStyle: { 'textAlign': 'left' },
      menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
      filter: true,
      sortable: true,
      resizable: true,
      enableRowGroup: true,
    }
  }, []);

  const [columnDefs, setColumnDefs] = useState([
    { headerName: "AccountNum", field: "AccountNum", filter: true},
    { headerName: "Location", field: "Location", filter: true },
    { headerName: "Volume", field: "Volume", filter: true },
    { headerName: "NumJobs", field: "NumJobs" }
  ]);


  // Save the grid's current state
  const saveGridState = () => {
    if (saveTimeoutRef.current) {
      clearTimeout(saveTimeoutRef.current); // Clear existing timeout if present
    }

    // Set a new timeout to save the grid state after 2 seconds
    saveTimeoutRef.current = setTimeout(() => {
      if (gridRef.current) {
        const columnState = gridRef.current.columnApi.getColumnState();
        const columnGroupState = gridRef.current.columnApi.getColumnGroupState();
        const filterState = gridApi.current.getFilterModel();
        const gridState = { columnState, columnGroupState, filterState };
        localStorage.setItem('fleetAccountsGridState', JSON.stringify(gridState));
        console.log('grid saved');
      } else {
        console.warn('getColumnState is not a function');
      }
    }, 1000);
  };


  // Load the grid's saved state
  const loadGridState = () => {
    const savedState = localStorage.getItem('fleetAccountsGridState');
    if (savedState) {
      const gridState = JSON.parse(savedState);
      console.log('loading state')
      gridRef.current.columnApi.applyColumnState({ state: gridState.columnState, applyOrder: true});
      gridRef.current.columnApi.setColumnGroupState(gridState.columnGroupState);
      gridApi.current.setFilterModel(gridState.filterState);
      console.log('loading stateeee')
    }
  };

  const onGridReady = params => {
    gridApi.current = params.api;
    loadGridState(); // Load the saved grid state
  };

  return (

                <AgGridReact
                  ref={gridRef}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  rowData={rowData}
                  animateRows={true}
                  onToolPanelVisibleChanged={saveGridState}
                  onColumnVisible={saveGridState} // When a column is shown/hidden
                  onColumnPinned={saveGridState}   // When a column is pinned/unpinned
                  onColumnMoved={saveGridState}
                  onColumnResized={saveGridState}
                  onColumnRowGroupChanged={saveGridState}
                  sideBar={{
                    toolPanels: [
                      {
                        id: 'columns',
                        labelDefault: 'Columns',
                        labelKey: 'columns',
                        iconKey: 'columns',
                        toolPanel: 'agColumnsToolPanel',
                      },
                      {
                        id: 'filters',
                        labelDefault: 'Filters',
                        labelKey: 'filters',
                        iconKey: 'filter',
                        toolPanel: 'agFiltersToolPanel',
                      },
                      // other tool panels as needed
                    ],
                    defaultToolPanel: null, // This will have the sidebar closed by default
                  }}
                  onGridReady={onGridReady}
                  onCellClicked={handleRowClick}

                />


  );
}

export default AccountsGrid;