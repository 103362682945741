import React, { useState, useEffect, useRef, useMemo, forwardRef, useImperativeHandle } from 'react';
import { AgGridReact } from 'ag-grid-react';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Button,
  Text,
  HStack,
  VStack,
  Grid,
  Card,
  CardBody,
  CardHeader,
  Spinner
} from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { userAtom } from './../../atoms';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css'; // or 'ag-theme-material.css';
import 'ag-grid-enterprise';
import grab from '../../utils/grab'
import { CalendarIcon } from '@chakra-ui/icons';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import Papa from 'papaparse';
import AccountsGrid from './accountsGrid';
pdfMake.vfs = pdfFonts.pdfMake.vfs;


function AccountSelectedGrid({selectedAccountData, showAccountGrid}, ref) {
  const saveTimeoutRef = useRef(null);
  const gridApi = useRef(null);
  const gridRef = useRef(null);

  const getGridData = async ()=> {
    return gridApi.current.getDataAsCsv();
  };

  useImperativeHandle(ref, () => ({
    getGridData
  }));
  useEffect(() => {
    window.addEventListener('resize', () => {
      gridApi.current && gridApi.current.sizeColumnsToFit();
    });
  }, []);

  useEffect(() => {
    gridApi.current && gridApi.current.sizeColumnsToFit();
  }, [selectedAccountData, showAccountGrid]);



  const [columnDefs, setColumnDefs] = useState([
    { headerName: "Account/Loc", field: "AccountNum", enableRowGroup: true },
    { headerName: "Truck Number", field: "TruckNum", enableRowGroup: true },
    { headerName: "Sale Number", field: "SaleNumber", enableRowGroup: true },
    { headerName: "Volume", field: "Volume", aggFunc: 'sum', footerValueGetter: 'totalVolume' },
    { headerName: "Running Total", field: "RunningTotal", enableRowGroup: true },
    { headerName: "VIN", field: "VIN", enableRowGroup: true },
    { headerName: "AKA", field: "AKA", enableRowGroup: true },
    { headerName: "Transaction Number", field: "TransactionNumber", enableRowGroup: true },
    { headerName: "Time", field: "TSDate", enableRowGroup: true },

  ]);

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: { 'textAlign': 'left' },
      menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
      filter: true,
      sortable: true,
      resizable: true,
      enableRowGroup: true,
    }
  }, []);

  const saveGridState = () => {
    if (saveTimeoutRef.current) {
      clearTimeout(saveTimeoutRef.current); // Clear existing timeout if present
    }

    // Set a new timeout to save the grid state after 2 seconds
    saveTimeoutRef.current = setTimeout(() => {
      if (gridRef.current) {
        const columnState = gridRef.current.columnApi.getColumnState();
        const columnGroupState = gridRef.current.columnApi.getColumnGroupState();
        const filterState = gridApi.current.getFilterModel();
        const gridState = { columnState, columnGroupState, filterState };
        localStorage.setItem('fleetAccountSelectedGridState', JSON.stringify(gridState));
        console.log('grid saved');
      } else {
        console.warn('getColumnState is not a function');
      }
    }, 1000);
  };


  // Load the grid's saved state
  const loadGridState = () => {
    const savedState = localStorage.getItem('fleetAccountSelectedGridState');
    if (savedState) {
      const gridState = JSON.parse(savedState);
      console.log('loading state')
      gridRef.current.columnApi.applyColumnState({ state: gridState.columnState, applyOrder: true});
      gridRef.current.columnApi.setColumnGroupState(gridState.columnGroupState);
      gridApi.current.setFilterModel(gridState.filterState);
      console.log('loading stateeee')
    }
  };

  const onGridReady = params => {
    gridApi.current = params.api;
    loadGridState(); // Load the saved grid state
  };




  return (

                  <AgGridReact
                    ref={gridRef}
                    columnDefs={columnDefs}
                    rowData={selectedAccountData}
                    defaultColDef={defaultColDef}
                    onToolPanelVisibleChanged={saveGridState}
                    onColumnVisible={saveGridState} // When a column is shown/hidden
                    onColumnPinned={saveGridState}   // When a column is pinned/unpinned
                    onColumnMoved={saveGridState}
                    onColumnResized={saveGridState}
                    onColumnRowGroupChanged={saveGridState}
                    sideBar={{
                      toolPanels: [
                        {
                          id: 'columns',
                          labelDefault: 'Columns',
                          labelKey: 'columns',
                          iconKey: 'columns',
                          toolPanel: 'agColumnsToolPanel',
                        },
                        {
                          id: 'filters',
                          labelDefault: 'Filters',
                          labelKey: 'filters',
                          iconKey: 'filter',
                          toolPanel: 'agFiltersToolPanel',
                        },
                        // other tool panels as needed
                      ],
                      defaultToolPanel: null, // This will have the sidebar closed by default
                    }}
                    onGridReady={onGridReady}
                    groupIncludeTotalFooter={true}
                    rowGroupPanelShow='always'
                  />
  );
}

export default forwardRef(AccountSelectedGrid);
