// Layout.js
import React from 'react';
import SideBar from '../Sidebar';

function EmbedLayout({ children }) {
  return (
    <>
      {children}
    </>
  );
}

export default EmbedLayout;
