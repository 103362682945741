import React, { useState, useEffect, useRef, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Button,
  Text,
  HStack,
  VStack,
  Grid,
  Card,
  CardBody,
  CardHeader,
  Spinner, useToast,

} from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { userAtom } from './../../atoms';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css'; // or 'ag-theme-material.css';
import 'ag-grid-enterprise';
import grab from '../../utils/grab'
import { CalendarIcon } from '@chakra-ui/icons';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import Papa from 'papaparse';
import moment from 'moment';

pdfMake.vfs = pdfFonts.pdfMake.vfs;


function FleetReport() {
  const [showFilters, setShowFilters] = useState(false);
  const [selectedAccountData, setSelectedAccountData] = useState([]);
  const [showAccountGrid, setShowAccountGrid] = useState(true);
  const [searching, setSearhing] = useState(false);
  const [user, setUser] = useAtom(userAtom);
  const current = moment.utc().startOf('day').toDate();
  const nextDay = moment.utc(current).add(1, 'days').toDate();
  const toast = useToast();
  const [startDate, setStartDate] = useState(current);
  const [endDate, setEndDate] = useState(nextDay);
  const gridApi = useRef(null);
  const gridRef = useRef(null);
  const saveTimeoutRef = useRef(null);


  useEffect(() => {
    let filterState = localStorage.getItem('filterState');
    setShowFilters(filterState === 'true');
    //window.addEventListener('resize', () => {
      //gridApi.current && gridApi.current.sizeColumnsToFit();
    //});
  }, []);

  useEffect(() => {
    //gridApi.current && gridApi.current.sizeColumnsToFit();
  }, [selectedAccountData, showAccountGrid, showFilters]);

  useEffect(() => {
    if (user.locationId){
      fetchAccountDetails();
    }
  }, [user.locationId]);


  const fetchAccountDetails = async (accountNum) => {
    try {
      setSearhing(true)
      const start = startDate.toISOString();
      const end = endDate.toISOString();
      const response = await grab.get(`/company-fleet`, {
        params: {
          locationId: user.locationId,
          startDate: start,
          endDate: end,
        }
      });
      console.log(`Fetched ${response.data.length} fleet records.`);
      setSelectedAccountData(response.data);
      setSearhing(false)
    } catch (error) {
      console.error('Error fetching account details:', error);
      setSearhing(false)
    }
  };

  const handleSearch = () => {
    fetchAccountDetails();
  };

  const handleStartDateChange = (date) => {
    const daysDifference = moment(endDate).diff(moment(date), 'days');

    //if (daysDifference > 7) {
    //  toast({
    //    title: "Date Range Error",
    //    description: "You can only select a date range of up to 7 days.",
    //    status: "error",
    //    duration: 5000,
    //    isClosable: true,
    //  });
    //  return; // return early to stop the function here.
    //}

    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    const daysDifference = moment(date).diff(moment(startDate), 'days');

    //if (daysDifference > 7) {
    //  toast({
    //    title: "Date Range Error",
    //    description: "You can only select a date range of up to 7 days.",
    //    status: "error",
    //    duration: 5000,
    //    isClosable: true,
    //  });
    //  return; // return early to stop the function here.
    //}

    setEndDate(date);
  };




  const [columnDefs, setColumnDefs] = useState([
    { headerName: "Fleet Seq Number", field: "fleet_seq_number", enableRowGroup: true },
    { headerName: "Account Number", field: "accountNum", enableRowGroup: true },
    { headerName: "Location", field: "location", enableRowGroup: true },
    { headerName: "Fleet Fill Date Time", field: "fleetFillDateTime", enableRowGroup: true },
    { headerName: "Fleet Fill Timestamp", field: "fleetFillTimestamp", enableRowGroup: true },
    { headerName: "Fleet VIN Number", field: "fleetVinNumber", enableRowGroup: true },
    {
      headerName: "Fleet Volume",
      field: "fleetVolume",
      enableRowGroup: true,
      aggFunc: 'sum'
    },
    { headerName: "Fleet Running Total", field: "fleetRunningTotal", enableRowGroup: true },
    { headerName: "Server Time", field: "serverTime", enableRowGroup: true },
    { headerName: "Telephone Number", field: "telephoneNumber", enableRowGroup: true },
    { headerName: "Truck Number", field: "truckNum", enableRowGroup: true },
    { headerName: "Sale Number", field: "saleNum", enableRowGroup: true },
    { headerName: "Ticket Number", field: "ticketNum", enableRowGroup: true },
    { headerName: "AKA", field: "AKA", enableRowGroup: true },
    { headerName: "JSON Object", field: "json_obj", enableRowGroup: true },
    { headerName: "Asset Scanned", field: "assetScanned", enableRowGroup: true },
    { headerName: "VIN In Table", field: "vinInTable", enableRowGroup: true },
    { headerName: "Delivery Started", field: "delivery_started", enableRowGroup: true },
    { headerName: "Delivery Type", field: "delivery_type", enableRowGroup: true },
    { headerName: "CR Group ID", field: "CRGroupID", enableRowGroup: true },
    { headerName: "CR Route ID", field: "CRRouteID", enableRowGroup: true },
    { headerName: "Customer Route ID", field: "CustRouteID", enableRowGroup: true },
    { headerName: "Customer Truck Number", field: "CustTruckNum", enableRowGroup: true },
    { headerName: "Temperature", field: "Temperature", enableRowGroup: true },
    { headerName: "Office Received", field: "officeReceived", enableRowGroup: true },
    { headerName: "Fleet License Plate", field: "fleetLicensePlate", enableRowGroup: true },
    { headerName: "Fleet Description", field: "fleetDescription", enableRowGroup: true }
  ]);


  const defaultColDef = useMemo(() => {
    return {
      cellStyle: { 'textAlign': 'left' },
      menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
      filter: true,
      sortable: true,
      resizable: true,
      enableRowGroup: true,
      width: 200
    }
  }, []);

  const handleHideFilter = () => {
    const status = !showFilters;
    console.log(status);
    setShowFilters(status);
    localStorage.setItem('filterState', JSON.stringify(status));
  };

  const saveGridState = () => {
    if (saveTimeoutRef.current) {
      clearTimeout(saveTimeoutRef.current); // Clear existing timeout if present
    }

    // Set a new timeout to save the grid state after 2 seconds
    saveTimeoutRef.current = setTimeout(() => {
      if (gridRef.current) {
        const columnState = gridRef.current.columnApi.getColumnState();
        const columnGroupState = gridRef.current.columnApi.getColumnGroupState();
        const filterState = gridApi.current.getFilterModel();
        const gridState = { columnState, columnGroupState, filterState };
        localStorage.setItem('companyFleetGridState', JSON.stringify(gridState));
        console.log('grid saved');
      } else {
        console.warn('getColumnState is not a function');
      }
    }, 1000);
  };


  // Load the grid's saved state
  const loadGridState = () => {
    const savedState = localStorage.getItem('companyFleetGridState');
    if (savedState) {
      const gridState = JSON.parse(savedState);
      console.log('loading state')
      gridRef.current.columnApi.applyColumnState({ state: gridState.columnState, applyOrder: true});
      gridRef.current.columnApi.setColumnGroupState(gridState.columnGroupState);
      gridApi.current.setFilterModel(gridState.filterState);
      console.log('loading state')
    }
  };

  const onGridReady = params => {
    gridApi.current = params.api;
    loadGridState(); // Load the saved grid state
  };

  const DateInput = React.forwardRef(({ value, onClick, onChange }, ref) => (
    <InputGroup>
    <InputLeftElement
      pointerEvents="none"
      children={<CalendarIcon color="gray.400" />}
    />
    <Input
      onClick={onClick}
      value={value}
      ref={ref}
      onChange={onChange}
      placeholder="Select date"
    />
  </InputGroup>
  ));
  
  
  return (
    <Box width={'100%'} height={'100%'}>
      <Grid templateColumns={showFilters ? { base: '1fr', md: '8fr 2fr' } : { base: '1fr' }} gap={3} minH="100vh">
        <Card width={"100%"} height={'100%'} minH='500px'>
          <CardHeader>
            <HStack justify={'space-between'}>
              <Text fontSize="xl" fontWeight="bold">
                Fleet Report
              </Text>
              <Button onClick={handleHideFilter} colorScheme="blue" mt={3}>
                  {showFilters ? 'Hide Filter' : 'Show Filter'}
              </Button>

            </HStack>
            
          </CardHeader>
          <CardBody>
          <Box className="ag-theme-alpine" width={"100%"} height={580}>
              <AgGridReact
                ref={gridRef}
                showFooter={true}

                columnDefs={columnDefs}
                rowData={selectedAccountData}
                defaultColDef={defaultColDef}
                onToolPanelVisibleChanged={saveGridState}
                onColumnVisible={saveGridState} // When a column is shown/hidden
                onColumnPinned={saveGridState}   // When a column is pinned/unpinned
                onColumnMoved={saveGridState}
                onColumnResized={saveGridState}
                onColumnRowGroupChanged={saveGridState}
                sideBar={{
                  toolPanels: [
                    {
                      id: 'columns',
                      labelDefault: 'Columns',
                      labelKey: 'columns',
                      iconKey: 'columns',
                      toolPanel: 'agColumnsToolPanel',
                    },
                    {
                      id: 'filters',
                      labelDefault: 'Filters',
                      labelKey: 'filters',
                      iconKey: 'filter',
                      toolPanel: 'agFiltersToolPanel',
                    },
                    // other tool panels as needed
                  ],
                  defaultToolPanel: null, // This will have the sidebar closed by default
                }}
                groupIncludeFooter={true}
                groupIncludeTotalFooter={true}
                onGridReady={onGridReady}
                rowGroupPanelShow='always'



              />
          </Box>
          </CardBody>
        </Card>
        {showFilters && (
        <VStack spacing={3} align="start"> {/* Wrap the date selection card in a VStack */}

        <Card height="auto">
          <CardHeader>
            <Text fontWeight="bold">
              Select Date Range
            </Text>
          </CardHeader>
          <CardBody>
              <FormLabel>Start Date</FormLabel>
              <DatePicker
                selected={startDate}
                onChange={handleStartDateChange}
                w="30%"
                customInput={<DateInput />}
              />
              <FormLabel mt={2} >End Date</FormLabel>
              <DatePicker
                selected={endDate}
                customInput={<DateInput />}
                onChange={handleEndDateChange}
              />
              <Button onClick={handleSearch} colorScheme="blue" w={'40%'} mt={3}>
              { searching ? <Spinner size={'sm'}/> : 'Search'}
              </Button>
          </CardBody>
        </Card>
        
        </VStack>
        )}
      </Grid>
    </Box>
  );
}

export default FleetReport;
